import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from './_vfs_fonts.js';

pdfMake.vfs = pdfFonts;

export function Ingredients(props) {
    // console.log('props', props)
    return {
        $template: '#ingredients-template',
        title: props.title,
        slug: props.slug,
        initialServings: props.servings,
        servings: props.servings,
        ingredientsObject: props.ingredientsObject,
        resetServings() {
            this.servings = this.initialServings
        },
        incServings() {
            this.servings++
        },
        decServings() {
            if(this.servings > 1) {
                this.servings--
            }
        },
        print() {
            const servings = this.servings
            const initialServings = this.initialServings
            let dd  = {
                info: {
                    title: props.title + ' ingredients shopping list',
                    author: 'Fab Flour',
                    subject: 'An ingredient list from Fab Flour',
                },
                content: [
                    {text: this.title, style: 'header'},
                    {text: 'Ingredients shopping list', style: 'subHeader'}
                ],
                styles: {
                    header: {
                        bold: true,
                        fontSize: 25,
                        marginBottom: 5
                    },
                    subHeader: {
                        bold: true,
                        fontSize: 20,
                        marginBottom: 20
                    },
                    groupHeader: {
                        bold: true,
                        fontSize: 15,
                        marginBottom: 10
                    },
                    table: {
                        marginBottom: 20,
                        paddingLeft: function (i, node) { return 100; },
                    },
                    cell: {
                        margin: 5
                    }
                },
                defaultStyle: {
                    font: 'Roboto',
                    fontSize: 12
                }
            }
            this.ingredientsObject.forEach(function (group) {
                if(group.title) {
                    dd.content.push({text: group.title, style: 'groupHeader'})
                }
                let groupList = []
                group.table.forEach(function (item, i) {
                    const bgColour = (i % 2 == 0) ? '#eee' : null
                    groupList.push([
                        {text: '', style: "cell"},
                        {  
                            text: item.qty > 0 ? ((item.qty / initialServings) * servings).toFixed(2).replace(/\.00$/, '') + (item.unit == 'tbsp' || item.unit == 'tsp' ? ' ' : '') + item.unit : '', 
                            border: [false], 
                            fillColor: bgColour,
                            style: "cell"
                        },
                        {text: item.text, border: [false], fillColor: bgColour, style: "cell"}
                    ])
                });
                dd.content.push({
                    style: 'table',
                    table: {
                        widths: [20, 'auto', '*'],
                        body: groupList
                    }
                })
            });
            pdfMake.createPdf(dd).open();
        }
    }
}