export function StepByStep(props) {
    console.log('props ', props.method)
    return {
        $template: '#step-by-step-template',
        method: props.method,
        stepsAmount: props.method.length,
        currentStep: 0,
        currentStepTitle: props.method[0].title,
        currentStepText: props.method[0].text,
        currentStepImage: props.method[0].image,
        open: false,
        start() {
            this.open = true
        },
        stop() {
            this.open = false
        },
        exit() {
            this.stop()
            const atEnd = this.currentStep == (this.stepsAmount - 1)
            this.currentStep = atEnd ? 0 : this.currentStep
            this.currentStepTitle = atEnd ? this.method[0].title : this.currentStepTitle
            this.currentStepText = atEnd ? this.method[0].text : this.currentStepText
            this.currentStepImage = atEnd ? this.method[0].image : this.currentStepImage
        },
        nextStep(thisProps) {
            const output = thisProps.output
            output.scrollTop = 0;
            if(this.currentStep < (this.stepsAmount - 1)) {
                this.currentStep++
                this.currentStepTitle = props.method[this.currentStep].title
                this.currentStepText = props.method[this.currentStep].text
                this.currentStepImage = props.method[this.currentStep].image
            }
        },
        prevStep(thisProps) {
            const output = thisProps.output
            output.scrollTop = 0;
            if(this.currentStep > 0) {
                this.currentStep--
                this.currentStepTitle = props.method[this.currentStep].title
                this.currentStepText = props.method[this.currentStep].text
                this.currentStepImage = props.method[this.currentStep].image
            }
        }
    }
  }