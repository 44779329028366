import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from './_vfs_fonts.js';

pdfMake.vfs = pdfFonts;

import { convert } from 'html-to-text';

function isPlural(number) {
    return number > 1 ? 's' : ''
}

function convertMinutes(minutes) {
    const hours = Math.floor(minutes/60)
    const mins = Math.floor(minutes%60)
    const result = (hours > 0 ? hours + ' hour' + isPlural(hours) + ' ' : '') + (mins > 0 ? mins + ' minute' + isPlural(mins) : '')
    return result
}

async function fetchRemoteImage(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error('Error fetching image:', error);
      return null;
    }
}

function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(blob);
    });
}

async function useBase64Image(url) {
    try {
        const blob = await fetchRemoteImage(url);
        if (blob) {
            const base64Data = await blobToBase64(blob);
            return base64Data; // Return the base64 encoded data
        } else {
            return null;
        }
    } catch (error) {
        console.error('Error in useBase64Image:', error);
        throw error; // Re-throw the error for handling higher up the call stack if needed
    }
}

function generatePDF(props, featureImage) {
    let dd  = {
        info: {
            title: props.title + ' recipe',
            author: 'Fab Flour',
            subject: 'A recipe from Fab Flour',
        },
        content: [],
        styles: {
            intro: {
                marginBottom: 30
            },
            header: {
                // bold: true,
                fontSize: 25,
                marginBottom: 20
            },
            sectionHeader: {
                // bold: true,
                fontSize: 20,
                marginBottom: 15
            },
            groupHeader: {
                // bold: true,
                fontSize: 15,
                marginBottom: 10
            },
            table: {
                marginBottom: 20,
                paddingLeft: function (i, node) { return 100; },
            },
            mb20: {
                marginBottom: 20,
            },
            mb10: {
                marginBottom: 10,
            },
            mb5: {
                marginBottom: 5,
            },
            cell: {
                margin: 5
            },
        },
        defaultStyle: {
            font: 'Roboto',
            fontSize: 12,
            columnGap: 40
        }
    }
    if(featureImage) {
        dd.content.push({
            columns: [
                {
                    image: featureImage,
                    width: 160,
                },
                [
                    {text: props.title, style: 'header'},
                    {text: convert(props.description, { wordwrap: false }), style: 'mb10'},
                    {text: 'Prep time: ' + convertMinutes(props.prepTime), style: 'mb5' },
                    {text: 'Bake time: ' + convertMinutes(props.bakeTime), style: 'mb5'},
                    {text: 'Servings: ' + props.servings + ' ' + props.servingsText}
                ]
            ],
            style: 'intro'  
        })
    } else {
        dd.content.push({
            columns: [
                [
                    {text: props.title, style: 'header'},
                    {text: convert(props.description, { wordwrap: false }), style: 'mb10'},
                    {text: 'Prep time: ' + convertMinutes(props.prepTime), style: 'mb5' },
                    {text: 'Bake time: ' + convertMinutes(props.bakeTime), style: 'mb5'},
                    {text: 'Servings: ' + props.servings + ' ' + props.servingsText}
                ]
            ],
            style: 'intro'  
        })
    }
    if(props.ingredientsObject) {
        dd.content.push({text: 'Ingredients', style: 'sectionHeader'})
        props.ingredientsObject.forEach(function (group) {
            if(group.title) {
                dd.content.push({text: group.title, style: 'groupHeader'})
            }
            let groupList = []
            group.table.forEach(function (item, i) {
                const bgColour = (i % 2 == 0) ? '#eee' : null
                groupList.push([
                    {text: '', style: "cell"},
                    {  
                        text: item.qty > 0 ? (item.qty + (item.unit == 'tbsp' || item.unit == 'tsp' ? ' ' : '') + item.unit) : '', 
                        border: [false], 
                        fillColor: bgColour,
                        style: "cell"
                    },
                    {text: item.text, border: [false], fillColor: bgColour, style: "cell"}
                ])
            });
            dd.content.push({
                style: 'table',
                table: {
                    widths: [20, 'auto', '*'],
                    body: groupList
                }
            })
        });
    }
    if(props.methodObject) {
        dd.content.push({text: 'Method', style: 'sectionHeader', pageBreak: 'before'})
        props.methodObject.forEach(function (step, i) {
            dd.content.push({text: 'Step ' + (i + 1), style: 'groupHeader'})
            dd.content.push({text: step.text, style: 'mb20'})
        });
    }
    pdfMake.createPdf(dd).open();
}

export function PrintRecipe(props) {
    console.log('props', props)
    return {
        print() {
            console.log('print', props.title)
            let featureImage
            useBase64Image(props.image)
                .then(base64Data => {
                    featureImage = base64Data ? 'data:image/jpg;base64,'+base64Data : null
                    generatePDF(props, featureImage)
                    // Use the base64Data here, such as in pdfMake or other contexts
                    console.log('Base64 encoded image data:', base64Data);
                })
                .catch(error => {
                    console.error('Error fetching or encoding image:', error);
                    // Handle error appropriately
                });
        }
    }
  }