import { DotLottie } from '@lottiefiles/dotlottie-web';

// const clockJson = new URL('../json/clock-radial.json', import.meta.url).href
const clockJson = "https://res.cloudinary.com/satellite-creative/raw/upload/v1712073354/Fab%20Flour/radial_filpgk.lottie"

export function Time(props) {
    // console.log('props', props)
    let lottie
    return {
        $template: '#time-template',
        text: props.text,
        minutes: props.minutes,
        createLottie(props) {
            lottie = new DotLottie({
                autoplay: false,
                segment: [0, this.minutes],
                canvas: props.canvas,
                src: clockJson
            });
            setTimeout(function() {
                lottie.play()
            }, 500);
        }
    }
}