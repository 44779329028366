import { Notify } from './_notify'

import defaultIcon from '../imgs/icon.png'
const notificationIcon = defaultIcon

export function Timer(props) {
    console.log('props', props)
    let intervalID;
    const bakeTime = props.bakeTime ?? 60
    const defaultHours =  Math.floor(bakeTime/60) >= 1 ? Math.floor(bakeTime/60) : 0
    const defaultMinutes =  Math.floor(bakeTime%60)
    const defaultSeconds =  ((defaultHours*60) + defaultMinutes)*60
    return {
        $template: '#timer-template',
        hours: defaultHours,
        minutes: defaultMinutes,
        seconds: defaultSeconds,
        totalSeconds: defaultSeconds,
        showReset: false,
        counting: false,
        open: false,
        setSeconds() {
            this.seconds =  ((this.hours*60) + this.minutes)*60;
            this.totalSeconds =  ((this.hours*60) + this.minutes)*60;
        },
        setHours(e, hours = +e.target.value) {
            this.hours = hours
            this.setSeconds()
        },
        setMinutes(e, mins = +e.target.value) {
            this.minutes = mins
            this.setSeconds()
        },
        stopTimer() {
            console.log('stop')
            clearInterval(intervalID)
            if(this.seconds < 1 && this.counting) {
                Notify('Cook timer', { 
                    body: `Timer: Complete`,
                    icon: notificationIcon,
                })
            } 
            this.counting = false
            this.showReset = false
        },
        startTimer() {
            Notify('Cook timer!', { 
                body: `Timer: Started`,
                icon: notificationIcon,
            })
            console.log('start')
            this.counting = true
            this.showReset = true
            intervalID = setInterval(() => {
                if(this.seconds > 0) {
                    this.seconds--
                    if(this.seconds < 61 && this.seconds % 30 == 0) {
                        Notify('Cook timer!', { 
                            body: `You have ${this.seconds} seconds left`,
                            icon: notificationIcon
                        })
                    }
                } else {
                    this.stopTimer()
                }
            }, 1000);
        },
        pauseTimer() {
            console.log('pause')
            clearInterval(intervalID)
            this.counting = false
        },
        resetTimer() {
            console.log('reset')
            clearInterval(intervalID)
            this.counting = false
            this.showReset = false
            this.seconds = this.totalSeconds
        }
    }
}