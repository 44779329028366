import '../css/app.scss';

import 'lazysizes';

//import petite-vue and create app
import { createApp, reactive } from 'petite-vue'

import { Lottie } from './_lottie'
import { swipers } from './_swiper'

import { ConvertTemperature, ConvertVolume, ConvertMass } from './_convert'
import { Reader } from './_reader'
import { Time } from './_time'
import { Timer } from './_timer'
import { PrintRecipe } from './_printRecipe'
import { Ingredients } from './_ingredients'
import { StepByStep } from './_stepByStep'

const main = async () => {
  // Create our vue instance
  const app = createApp({
    // share it with app scopes
    Lottie,
    swipers,
    ConvertTemperature,
    ConvertVolume,
    ConvertMass,
    Ingredients,
    Reader,
    Time,
    Timer,
    PrintRecipe,
    StepByStep,
  }).mount()

  // Mount the app
  return app.mount('#component-container');
};

// Execute async function
main().then(() => {
  // AOS.init({
  //   once: true
  // })
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR!!")
  });
}