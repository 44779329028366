export function ConvertTemperature(props) {
    return {
      $template: '#convert-temperature-template',
      c: 180,
      f: 356,
      gm: 4,
      setCelsius(e, c = +e.target.value) {
        this.c = c
        this.f = ((c*1.8)+32).toFixed(0)
        this.gm = 
          c <= 110 ? 1/4 : 
          c <= 130 ? 1/2 : 
          c <= 140 ? 1 :
          c <= 150 ? 2 :
          c <= 170 ? 3 :
          c <= 180 ? 4 :
          c <= 190 ? 5 :
          c <= 200 ? 6 :
          c <= 220 ? 7 :
          c <= 230 ? 8 : 9 
      },
      setFahrenhelt(e, f = +e.target.value) {
        this.f = f
        this.c = ((f-32)/1.8).toFixed(0)
        this.gm = 
          f <= 230 ? 1/4 : 
          f <= 266 ? 1/2 : 
          f <= 284 ? 1 :
          f <= 302 ? 2 :
          f <= 338 ? 3 :
          f <= 356 ? 4 :
          f <= 374 ? 5 :
          f <= 392 ? 6 :
          f <= 428 ? 7 :
          f <= 446 ? 8 : 9 
      },
      setGasMark(e, gm = +e.target.value) {
        this.gm = gm
        this.c = 
          gm <= 1/4 ? 110 : 
          gm <= 1/2 ? 130 : 
          gm <= 1 ? 140 :
          gm <= 2 ? 150 :
          gm <= 3 ? 170 :
          gm <= 4 ? 180 :
          gm <= 5 ? 190 :
          gm <= 6 ? 200 :
          gm <= 7 ? 220 :
          gm <= 8 ? 230 : 240 
        this.f = 
          gm <= 1/4 ? 230 : 
          gm <= 1/2 ? 266 : 
          gm <= 1 ? 284 :
          gm <= 2 ? 302 :
          gm <= 3 ? 338 :
          gm <= 4 ? 356 :
          gm <= 5 ? 374 :
          gm <= 6 ? 392 :
          gm <= 7 ? 428 :
          gm <= 8 ? 446 : 464
      }
    }
  }
  
  export function ConvertVolume(props) {
    return {
      $template: '#convert-volume-template',
      inputUnit: 'ml',
      outputUnit: 'fluk',
      l: 0.15,
      ml: 150,
      tbsp: 0.056,
      tsp: 0.17,
      fluk: 5.28,
      flus: 5.07,
      cupuk: 0.53,
      cupus: 0.63,
      pintuk: 0.26,
      pintus: 0.32,
      setLiter(e, l = +e.target.value) {
        this.l = l
        this.ml = (l*1000).toFixed(2).replace(/\.00$/, '');
        this.tbsp = (l*56.312).toFixed(2).replace(/\.00$/, '');
        this.tsp = (l*168.9).toFixed(2).replace(/\.00$/, '');
        this.fluk = (l*35.195).toFixed(2).replace(/\.00$/, '');
        this.flus = (l*33.814).toFixed(2).replace(/\.00$/, '');
        this.cupuk = (l*3.52).toFixed(2).replace(/\.00$/, '');
        this.cupus = (l*4.167).toFixed(2).replace(/\.00$/, '');
        this.pintuk = (l*1.76).toFixed(2).replace(/\.00$/, '');
        this.pintus = (l*2.113).toFixed(2).replace(/\.00$/, '');
      },
      setMilliliter(e, ml = +e.target.value) {
        this.ml = ml
        this.l = (ml/1000).toFixed(2).replace(/\.00$/, '');
        this.tbsp = (ml/17.758).toFixed(2).replace(/\.00$/, '');
        this.tsp = (ml/5.919).toFixed(2).replace(/\.00$/, '');
        this.fluk = (ml/28.413).toFixed(2).replace(/\.00$/, '');
        this.flus = (ml/29.574).toFixed(2).replace(/\.00$/, '');
        this.cupuk = (ml/284.1).toFixed(2).replace(/\.00$/, '');
        this.cupus = (ml/240).toFixed(2).replace(/\.00$/, '');
        this.pintuk = (ml/568.3).toFixed(2).replace(/\.00$/, '');
        this.pintus = (ml/473.2).toFixed(2).replace(/\.00$/, '');
      },
      setTablespoon(e, tbsp = +e.target.value) {
        this.tbsp = tbsp
        this.l = (tbsp/56.312).toFixed(2).replace(/\.00$/, '');
        this.ml = (tbsp*17.758).toFixed(2).replace(/\.00$/, '');
        this.tsp = (tbsp*3).toFixed(2).replace(/\.00$/, '');
        this.fluk = (tbsp/1.6).toFixed(2).replace(/\.00$/, '');
        this.flus = (tbsp/1.665).toFixed(2).replace(/\.00$/, '');
        this.cupuk = (tbsp/16).toFixed(2).replace(/\.00$/, '');
        this.cupus = (tbsp/13.515).toFixed(2).replace(/\.00$/, '');
        this.pintuk = (tbsp/32).toFixed(2).replace(/\.00$/, '');
        this.pintus = (tbsp/26.646).toFixed(2).replace(/\.00$/, '');
      },
      setTeaspoon(e, tsp = +e.target.value) {
        this.tsp = tsp
        this.l = (tsp/168.9).toFixed(2).replace(/\.00$/, '');
        this.ml = (tsp*5.919).toFixed(2).replace(/\.00$/, '');
        this.tbsp = (tsp/3).toFixed(2).replace(/\.00$/, '');
        this.fluk = (tsp/4.8).toFixed(2).replace(/\.00$/, '');
        this.flus = (tsp/4.996).toFixed(2).replace(/\.00$/, '');
        this.cupuk = (tsp/48).toFixed(2).replace(/\.00$/, '');
        this.cupus = (tsp/40.545).toFixed(2).replace(/\.00$/, '');
        this.pintuk = (tsp/96).toFixed(2).replace(/\.00$/, '');
        this.pintus = (tsp/79.937).toFixed(2).replace(/\.00$/, '');
      },
      setFlUk(e, fluk = +e.target.value) {
        this.fluk = fluk
        this.l = (fluk/35.195).toFixed(2).replace(/\.00$/, '');
        this.ml = (fluk*28.413).toFixed(2).replace(/\.00$/, '');
        this.tbsp = (fluk*1.6).toFixed(2).replace(/\.00$/, '');
        this.tsp = (fluk*4.8).toFixed(2).replace(/\.00$/, '');
        this.flus = (fluk/1.041).toFixed(2).replace(/\.00$/, '');
        this.cupuk = (fluk/10).toFixed(2).replace(/\.00$/, '');
        this.cupus = (fluk/8.447).toFixed(2).replace(/\.00$/, '');
        this.pintuk = (fluk/20).toFixed(2).replace(/\.00$/, '');
        this.pintus = (fluk/16.654).toFixed(2).replace(/\.00$/, '');
      },
      setFlUs(e, flus = +e.target.value) {
        this.flus = flus
        this.l = (flus/33.814).toFixed(2).replace(/\.00$/, '');
        this.ml = (flus*29.574).toFixed(2).replace(/\.00$/, '');
        this.tbsp = (flus*1.665).toFixed(2).replace(/\.00$/, '');
        this.tsp = (flus*4.996).toFixed(2).replace(/\.00$/, '');
        this.fluk = (flus*1.041).toFixed(2).replace(/\.00$/, '');
        this.cupuk = (flus/9.608).toFixed(2).replace(/\.00$/, '');
        this.cupus = (flus/8.115).toFixed(2).replace(/\.00$/, '');
        this.pintuk = (flus/19.215).toFixed(2).replace(/\.00$/, '');
        this.pintus = (flus/16).toFixed(2).replace(/\.00$/, '');
      },
      setCupUk(e, cupuk = +e.target.value) {
        this.cupuk = cupuk
        this.l = (cupuk/3.52).toFixed(2).replace(/\.00$/, '');
        this.ml = (cupuk*284.1).toFixed(2).replace(/\.00$/, '');
        this.tbsp = (cupuk*16).toFixed(2).replace(/\.00$/, '');
        this.tsp = (cupuk*48).toFixed(2).replace(/\.00$/, '');
        this.fluk = (cupuk*10).toFixed(2).replace(/\.00$/, '');
        this.flus = (cupuk*9.608).toFixed(2).replace(/\.00$/, '');
        this.cupus = (cupuk*1.18388).toFixed(2).replace(/\.00$/, '');
        this.pintuk = (cupuk/2).toFixed(2).replace(/\.00$/, '');
        this.pintus = (cupuk/1.665).toFixed(2).replace(/\.00$/, '');
      },
      setCupUs(e, cupus = +e.target.value) {
        this.cupus = cupus
        this.l = (cupus/4.167).toFixed(2).replace(/\.00$/, '');
        this.ml = (cupus*240).toFixed(2).replace(/\.00$/, '');
        this.tbsp = (cupus*13.515).toFixed(2).replace(/\.00$/, '');
        this.tsp = (cupus*40.545).toFixed(2).replace(/\.00$/, '');
        this.fluk = (cupus*8.447).toFixed(2).replace(/\.00$/, '');
        this.flus = (cupus*8.115).toFixed(2).replace(/\.00$/, '');
        this.cupuk = (cupus/1.18388).toFixed(2).replace(/\.00$/, '');
        this.pintuk = (cupus/2.368).toFixed(2).replace(/\.00$/, '');
        this.pintus = (cupus/1.972).toFixed(2).replace(/\.00$/, '');
      },
      setPintUk(e, pintuk = +e.target.value) {
        this.pintuk = pintuk
        this.l = (pintuk/1.76).toFixed(2).replace(/\.00$/, '');
        this.ml = (pintuk*568.3).toFixed(2).replace(/\.00$/, '');
        this.tbsp = (pintuk*32).toFixed(2).replace(/\.00$/, '');
        this.tsp = (pintuk*96).toFixed(2).replace(/\.00$/, '');
        this.fluk = (pintuk*20).toFixed(2).replace(/\.00$/, '');
        this.flus = (pintuk*19.215).toFixed(2).replace(/\.00$/, '');
        this.cupuk = (pintuk*2).toFixed(2).replace(/\.00$/, '');
        this.cupus = (pintuk*2.368).toFixed(2).replace(/\.00$/, '');
        this.pintus = (pintuk*1.201).toFixed(2).replace(/\.00$/, '');
      },
      setPintUs(e, pintus = +e.target.value) {
        this.pintus = pintus
        this.l = (pintus/2.113).toFixed(2).replace(/\.00$/, '');
        this.ml = (pintus*473.2).toFixed(2).replace(/\.00$/, '');
        this.tbsp = (pintus*26.646).toFixed(2).replace(/\.00$/, '');
        this.tsp = (pintus*79.937).toFixed(2).replace(/\.00$/, '');
        this.fluk = (pintus*16.654).toFixed(2).replace(/\.00$/, '');
        this.flus = (pintus*16).toFixed(2).replace(/\.00$/, '');
        this.cupuk = (pintus*1.665).toFixed(2).replace(/\.00$/, '');
        this.cupus = (pintus*1.972).toFixed(2).replace(/\.00$/, '');
        this.pintuk = (pintus/1.201).toFixed(2).replace(/\.00$/, '');
      },
      setConvertInput(e) {
        this.inputUnit = e.target.value
      },
      setConvertOutput(e) {
        this.outputUnit = e.target.value
      },
    }
  }
  
  export function ConvertMass(props) {
    return {
      $template: '#convert-mass-template',
      inputUnit: 'g',
      outputUnit: 'oz',
      g: 100,
      oz: 3.53,
      setGram(e, g = +e.target.value) {
        this.g = g
        this.oz = (g/28.35).toFixed(2).replace(/\.00$/, '');
      },
      setOunce(e, mg = +e.target.value) {
        this.mg = mg
        this.g = (mg*28.35).toFixed(2).replace(/\.00$/, '');
      },
      setConvertInput(e) {
        this.inputUnit = e.target.value
      },
      setConvertOutput(e) {
        this.outputUnit = e.target.value
      },
    }
  }