let synth = window.speechSynthesis
let voices = []

export function Reader(props) {
    console.log('props', props)
    return {
        $template: '#reader-template',
        text: props.text,
        reading: false,
        id: props.id,
        readThis() {
            let msg = new SpeechSynthesisUtterance()
            voices = synth.getVoices();
            msg.voice = voices[0];
			msg.text = props.text
			msg.pitch = 1
			msg.rate = 1
			msg.volume = 1
           
            if(!this.reading) {
                synth.cancel()
                this.reading = true
                synth.speak(msg)
            } else {
                synth.cancel()
                this.reading = false
            }
            msg.onend = (event) => {
                this.reading = false
                console.log(this.id, 'end')
            };
            msg.onpause = (event) => {
                this.reading = false
                console.log(this.id, 'pause')
            };
            msg.onstart = (event) => {
                this.reading = true
                console.log(this.id, 'start')
            };
            msg.onresume = (event) => {
                this.reading = true
                console.log(this.id, 'resume')
            };
        }
    }
}