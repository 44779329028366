import Swiper from 'swiper';
import { Scrollbar, Pagination, Navigation, FreeMode, EffectCards } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/effect-cards';

const inspireSwiper = document.getElementById("js-swiper-inspire");

console.log(inspireSwiper)

const swiperCardObject = {
	effect: "cards",
	grabCursor: true,
	scrollbar: {
		el: '#js-swiper-inspire-scrollbar',
		draggable: true,
	},
	navigation: {
		nextEl: '#js-swiper-inspire-next',
		prevEl: '#js-swiper-inspire-prev',
	},
	modules: [Scrollbar, Pagination, Navigation, FreeMode, EffectCards],
}

const cardSwiper = new Swiper('#js-swiper-inspire', swiperCardObject);


// htmx.on('htmx:afterSwap', function(event) {
// 	setTimeout(function(){ 
// 		inspireSwiper.classList.add('green');
// 		const cardSwiper = new Swiper('#js-swiper-inspire', swiperCardObject);
// 	}, 0);
// });

// const allCardSwipers = document.querySelectorAll(".swiper-cards");
// export const cardSwipers = allCardSwipers.forEach(function (thisSwiper, i) {

// 	const elPagination = thisSwiper.querySelector(".swiper-pagination");
// 	const elScrollbar = thisSwiper.querySelector(".swiper-scrollbar");
// 	const elPrev = thisSwiper.querySelector(".swiper-button-prev");
// 	const elNext = thisSwiper.querySelector(".swiper-button-next");

// 	const cardSwiper = new Swiper(thisSwiper, {
// 		effect: "cards",
//       	grabCursor: true,
// 		  scrollbar: {
//             el: elScrollbar,
//             draggable: true,
//         },
// 		pagination: {
// 			el: elPagination,
// 			dynamicBullets: true,
// 			clickable: true,
// 		},
// 		navigation: {
// 			nextEl: elNext,
// 			prevEl: elPrev,
// 		},
// 		modules: [Scrollbar, Pagination, Navigation, FreeMode, EffectCards],
// 	});

// 	htmx.on('htmx:beforeSwap', function(event) {
// 		console.log('before!!')
// 		cardSwiper.destroy()
// 	});
// 	htmx.on('htmx:afterSwap', function(event) {
// 		console.log('after!!')
	
// 	});
// });

const allSwipers = document.querySelectorAll(".general-swiper");

export const swipers = allSwipers.forEach(function (thisSwiper, i) {

	const elPagination = thisSwiper.querySelector(".swiper-pagination");
	const elScrollbar = thisSwiper.querySelector(".swiper-scrollbar");
	const elPrev = thisSwiper.querySelector(".swiper-button-prev");
	const elNext = thisSwiper.querySelector(".swiper-button-next");

	let colMb = 1;
	let colSm = 1;
	let colMd = 1;
	let colLg = 1;
	let colXl = 1;
	if(thisSwiper.hasAttribute('data-col-mb')) {	
		const colMbData = thisSwiper.getAttribute('data-col-mb');
		const colSmData = thisSwiper.getAttribute('data-col-sm');
		const colMdData = thisSwiper.getAttribute('data-col-md');
		const colLgData = thisSwiper.getAttribute('data-col-lg');
		const colXlData = thisSwiper.getAttribute('data-col-xl');

		colMb = (colMbData.length && colMbData != '') ? colMbData : 1;
		colSm = colSmData != '' ? colSmData : colMb;
		colMd = colMdData != '' ? colMdData : colSm;
		colLg = colLgData != '' ? colLgData : colMd;
		colXl = colXlData != '' ? colXlData : colLg;

		console.log(colMb, colSm, colMd, colLg, colXl);
	} 
	const spaceBetween = thisSwiper.getAttribute('data-gap') ? Number(thisSwiper.getAttribute('data-gap')) : 6;
	const loop = thisSwiper.getAttribute('data-loop') ? Boolean(thisSwiper.getAttribute('data-loop')) : false;
	const swiper = new Swiper(thisSwiper, {
		slidesPerView: colMb != 'auto' ? +colMb : 'auto',
        slidesPerGroup: 1,
		breakpoints: {
			640: {
				slidesPerView: colSm != 'auto' ? +colSm : 'auto',
			},
			768: {
				slidesPerView: colMd != 'auto' ? +colMd : 'auto',
			},
			1024: {
				slidesPerView: colLg != 'auto' ? +colLg : 'auto',
            },
			1280: {
				slidesPerView: colXl != 'auto' ? +colXl : 'auto',
			}
		},
		// speed: 2000,
		autoHeight: false,
        loop: loop,
        grabCursor: true,
		spaceBetween: spaceBetween,
		autoplay: {
			delay: 2500
		},
		lazy: {
			loadPrevNext: true,
			loadPrevNextAmount: 2,
		},
		freeMode: {
			enabled: true,
			sticky: true
		},
        scrollbar: {
            el: elScrollbar,
            draggable: true,
        },
		pagination: {
			el: elPagination,
			dynamicBullets: true,
			clickable: true,
		},
		navigation: {
			nextEl: elNext,
			prevEl: elPrev,
		},
	
        modules: [Scrollbar, Pagination, Navigation, FreeMode],
	});
});